import { createApp } from '../../app'
import { useMainStore } from '../../store'
import { setPageContext } from '../../renderer/usePageContext'

import * as Sentry from '@sentry/vue'
import { Confetti } from 'vue-confetti'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'

export { render as onRenderClient }

async function loadData() {
  const urlWithoutQueryParams = window.location.pathname.split('?')[0]
  const domainSlug = urlWithoutQueryParams.split('/')[1]
  const mainStore = useMainStore()
  mainStore.domainSlug = domainSlug

  const path = urlWithoutQueryParams.replace(`/${domainSlug}`, '')

  await Promise.all([
    mainStore.fetchSite(),
    mainStore.fetchTheme({
      storyblokVersion: import.meta.env.VITE_STORYBLOK_VERSION,
    }),
  ])
  // We need to have the site first to load TOO's data
  await mainStore.fetchPageContent({
    storyblokVersion: import.meta.env.VITE_STORYBLOK_VERSION,
    path,
  })
}

let app
async function render(pageContext) {
  if (pageContext.isHydration) {
    const instance = createApp(pageContext, true)
    app = instance.app

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
          filterKeys: ['W0NLQRbOfxItOYBF'],
          behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
        }),
      ],
      environment: import.meta.env.VITE_SENTRY_ENV,
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: import.meta.env.PROD ? 0.1 : 1.0,

      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    })

    // Only works on client-side, so we need to add it here
    app.provide('confetti', new Confetti())
    app.use(VueSilentbox)

    instance.store.state.value = pageContext.initialStoreState

    setPageContext(app, {
      ...pageContext,
      urlOriginal: window.location.pathname,
    })
  }

  await loadData()

  if (pageContext.isHydration) {
    app.mount('#jrCB')
  } else {
    app.changePage(pageContext)
  }
}
